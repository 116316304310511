var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    {
      attrs: {
        header: {
          title: _vm.$t(
            "CRM.Companies.FleetSegments.create.mappingCategoriesTitle"
          ),
        },
        size: _vm.SIZES.medium,
      },
    },
    [
      _c("template", { slot: "body" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "CRM.Companies.FleetSegments.create.mappingCategoriesMessage"
              )
            ) +
            " "
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "ui-button",
            {
              on: {
                clickbutton: function ($event) {
                  return _vm.$emit("close-modal")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("Common.Actions.confirm")) + " ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }