<script>
export default {
  name: 'CompanyFleetSegmentsView',
};
</script>

<template>
  <div
    class="CompanyFleetSegmentsView"
    data-test-id="company_fleet_segments-view"
  >
    <RouterView />
  </div>
</template>
