var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DeleteFleetSegments",
        attrs: {
          header: {
            title: "Delete this fleet segment?",
            isClosable: true,
          },
          size: _vm.SIZES.small,
        },
        on: {
          "modal-closed": function ($event) {
            return _vm.$emit("close-modal")
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c("template", { slot: "body" }, [
        _vm._v(" Once deleted you cannot revert the changes. "),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "delete-fleet-segment-cancel" },
            on: {
              click: function ($event) {
                return _vm.$emit("close-modal")
              },
            },
          }),
          _c("DeleteButton", {
            attrs: {
              "data-test-id": "delete-fleet-segment-confirm",
              loading: _vm.fleetSegmentStatus.LOADING,
            },
            on: { click: _vm.removeFleetSegment },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }