// TODO: https://europcarmobility.atlassian.net/browse/SBCCC-1303
export const FULL_TIME_WORKING_HOURS = {
  monday: ['00:00-24:00'],
  tuesday: ['00:00-24:00'],
  wednesday: ['00:00-24:00'],
  thursday: ['00:00-24:00'],
  friday: ['00:00-24:00'],
  saturday: ['00:00-24:00'],
  sunday: ['00:00-24:00'],
};

export const EMPTY_WORKING_HOURS = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
};

export const WORKING_HOURS_TYPES = {
  always: 'always',
  scheduled: 'scheduled',
  notDedicated: 'not-dedicated',
  personalUseOfDedicated: 'personal-dedicated-use',
};
