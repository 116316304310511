<script>
import get from 'lodash/get';
import { mapActions, mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteButton,
    GenericModalComponent,
  },
  props: {
    fleetSegmentUuid: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.fleetSegment, {
      fleetSegmentStatus: state => get(state, 'fleetSegment.STATUS'),
    }),
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    ...mapActions(DOMAINS_MODEL.crm.fleetSegment, [
      'deleteFleetSegment',
    ]),
    ...mapMutations(DOMAINS_MODEL.crm.fleetSegment, [
      'resetData',
    ]),
    async removeFleetSegment() {
      await this.deleteFleetSegment(this.fleetSegmentUuid);
      if (!this.fleetSegmentStatus.ERROR) {
        this.notify({ message: 'Fleet Segment was successfully deleted!' });
      }
      this.$emit('close-modal');
      this.resetData();
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{
      title: 'Delete this fleet segment?',
      isClosable: true
    }"
    :size="SIZES.small"
    class="DeleteFleetSegments"
    v-on="$listeners"
    @modal-closed="$emit('close-modal')"
  >
    <template slot="body">
      Once deleted you cannot revert the changes.
    </template>
    <template slot="footer">
      <CancelButton
        data-test-id="delete-fleet-segment-cancel"
        @click="$emit('close-modal')"
      />
      <DeleteButton
        data-test-id="delete-fleet-segment-confirm"
        :loading="fleetSegmentStatus.LOADING"
        @click="removeFleetSegment"
      />
    </template>
  </GenericModalComponent>
</template>
