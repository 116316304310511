var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CompanyFleetSegmentsListView",
      attrs: { "data-test-id": "company_fleet_segment-view" },
    },
    [
      _c(
        "div",
        {
          staticClass: "mb-2 d-flex justify-content-between align-items-center",
        },
        [
          _c(
            "h1",
            { staticClass: "d-flex" },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("CRM.Companies.FleetSegments.list.title")) +
                  " "
              ),
              _c(
                "ui-tooltip",
                {
                  staticClass: "ml-2",
                  attrs: {
                    tooltip: _vm.$t("CRM.Companies.FleetSegments.list.tooltip"),
                  },
                },
                [
                  _c("ui-icon", {
                    attrs: {
                      icon: _vm.ICONS.info,
                      size: _vm.ICONS_SIZES.xSmall,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.hasEditPermission
            ? _c(
                "ui-button",
                {
                  attrs: { "data-test-id": "save-button" },
                  on: { clickbutton: _vm.createFleetSegment },
                },
                [_vm._v(" Create new fleet segment ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("MuiAlgoliaList", {
        ref: "fleetSegmentsTable",
        attrs: {
          index: _vm.ALGOLIA_INDEXES.fleetSegments,
          "table-config": _vm.contentCells,
          "item-actions": _vm.algoliaItemActions,
          filters: _vm.algoliaFilters,
          "empty-message": _vm.getNoResultsLabel("fleet segments"),
        },
      }),
      _vm.isDeleteModalOpen
        ? _c("DeleteFleetSegment", {
            attrs: {
              "fleet-segment-uuid": _vm.removableFleetSegment.uuid,
              "data-test-id": "delete-modal",
            },
            on: { "close-modal": _vm.onCloseDeleteModal },
          })
        : _vm._e(),
      _vm.isDeleteErrorModalOpen
        ? _c("FleetSegmentErrorModal", {
            attrs: {
              vehicles: _vm.errorVehicles,
              title: _vm.$t("CRM.Companies.FleetSegments.list.errorTitle"),
              message: _vm.$t("CRM.Companies.FleetSegments.list.errorMessage"),
              "action-text": _vm.$t("Common.Actions.ok"),
              "data-test-id": "error-modal",
            },
            on: {
              "modal-closed": function ($event) {
                _vm.isDeleteErrorModalOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }