var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-4", attrs: { "data-test-id": "fleet_segment-form" } },
    [
      _c("h1", { staticClass: "mb-3" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _c(
        "div",
        {
          staticClass:
            "emobg-background-color-white emobg-border-top-1 emobg-border-left-1 emobg-border-right-1 emobg-border-color-ground-light mt-2 position-relative",
        },
        [
          _c(
            "div",
            { staticClass: "emobg-background-color-ground-lightest p-3" },
            [
              _c("h2", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.editFleetSegment
                        ? _vm.$t("CRM.Companies.FleetSegments.edit.subtitle")
                        : _vm.$t("CRM.Companies.FleetSegments.create.subtitle")
                    ) +
                    " "
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "p-3 mb-2" },
            [
              _c("ui-text-input", {
                staticClass: "my-3",
                attrs: {
                  value: _vm.name,
                  placeholder: _vm.$t(
                    "CRM.Companies.FleetSegments.create.inputs.namePlaceholder"
                  ),
                  label: _vm.$t(
                    "CRM.Companies.FleetSegments.create.inputs.nameLabel"
                  ),
                  name: "name",
                  "data-test-id": "name-input",
                },
                on: { changevalue: ({ detail }) => (_vm.name = detail) },
              }),
              _c("ui-text-area", {
                staticClass: "mt-3",
                attrs: {
                  value: _vm.description,
                  label: _vm.$t(
                    "CRM.Companies.FleetSegments.create.inputs.commentsLabel"
                  ),
                  placeholder: _vm.$t(
                    "CRM.Companies.FleetSegments.create.inputs.commentsPlaceholder"
                  ),
                  name: "comments",
                  rows: "4",
                  "data-test-id": "comments-input",
                },
                on: { changevalue: ({ detail }) => (_vm.description = detail) },
              }),
            ],
            1
          ),
          _c(
            "FormCollapseSection",
            { staticClass: "pb-3", attrs: { title: "Vehicles" } },
            [
              _c(
                "div",
                { class: ["p-3", { disabled: !_vm.name }] },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between my-4",
                    },
                    [
                      _c("div", [
                        _c("h3", { staticClass: "mb-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.editFleetSegment
                                  ? _vm.$t(
                                      "CRM.Companies.FleetSegments.edit.addVehicles"
                                    )
                                  : _vm.$t(
                                      "CRM.Companies.FleetSegments.create.addVehicles"
                                    )
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "CRM.Companies.FleetSegments.create.addVehiclesDescription"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "ui-button",
                        {
                          attrs: {
                            disabled: !_vm.vehicles.length,
                            "data-test-id": "switch_view-button",
                          },
                          on: { clickbutton: _vm.switchAlgoliaView },
                        },
                        [_vm._v(" " + _vm._s(_vm.filtersLabel) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("MuiAlgoliaList", {
                    ref: "vehiclesList",
                    attrs: {
                      index: _vm.ALGOLIA_INDEXES.vehicles,
                      filters: _vm.algoliaFilter,
                      "table-config": _vm.contentCellsComputed,
                      "query-parameters": _vm.queryParameters,
                      actions: _vm.algoliaActions,
                      facets: _vm.algoliaFacets,
                      "is-export-enabled": "",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "FormCollapseSection",
            {
              staticClass: "pb-3",
              attrs: {
                title: _vm.$t(
                  "CRM.Companies.FleetSegments.create.scheduleTitle"
                ),
              },
            },
            [
              _c("div", { class: ["p-3", { disabled: !_vm.name }] }, [
                _c("div", { class: { disabled: !!_vm.editFleetSegment } }, [
                  _c("div", { staticClass: "my-2" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center my-2" },
                      [
                        _c("ui-radio", {
                          attrs: {
                            value: _vm.workingHoursType,
                            option: _vm.WORKING_HOURS_TYPES.always,
                            caption: "Always dedicated",
                            name: "always",
                            "data-test-id": "always-input",
                          },
                          on: { changevalue: _vm.setWorkingHoursAlways },
                        }),
                        _c(
                          "ui-tooltip",
                          {
                            staticClass: "ml-1",
                            attrs: {
                              placement: _vm.PLACEMENTS.right,
                              tooltip: "Reserved for employees only",
                            },
                          },
                          [
                            _c("ui-icon", {
                              attrs: {
                                icon: _vm.ICONS.infoFull,
                                color: _vm.GRAYSCALE.inkLight,
                                size: _vm.ICONS_SIZES.medium,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.company.personalDedicatedUse
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "PersonalUseOfDedicated d-block emobg-border-1 p-3 emobg-border-color-ground-light emobg-border-radius-large",
                            class: [
                              {
                                disabled:
                                  _vm.workingHoursType !==
                                  _vm.WORKING_HOURS_TYPES.always,
                              },
                            ],
                          },
                          [
                            _c("ui-toggle", {
                              staticClass: "d-block",
                              attrs: {
                                value: _vm.allowPersonalUseOfDedicated,
                                text: "Restrict the access of this Fleet Segment to employees with Personal Profile inside working hours",
                                "data-test-id": "personal-use-dedicated",
                                name: "activityToggle",
                              },
                              on: {
                                changevalue: _vm.setWorkingHoursFotPersonalUse,
                              },
                            }),
                            _vm.allowPersonalUseOfDedicated
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-block emobg-border-1 mt-3 p-3 emobg-border-color-ground-light emobg-border-radius-large",
                                    class: [
                                      {
                                        disabled:
                                          _vm.workingHoursType !==
                                          _vm.WORKING_HOURS_TYPES.always,
                                      },
                                    ],
                                  },
                                  [
                                    _c("h3", [
                                      _vm._v("Schedule working hours"),
                                    ]),
                                    _c("p", { staticClass: "mt-2" }, [
                                      _vm._v(
                                        " Hours when the fleet will be dedicated to employees with Business profile only. "
                                      ),
                                    ]),
                                    _c("WeekSchedule", {
                                      staticClass: "mt-3",
                                      attrs: {
                                        "day-names": _vm.dayNames,
                                        "is-disabled": !!_vm.editFleetSegment,
                                      },
                                      model: {
                                        value: _vm.workingHoursForPersonalUse,
                                        callback: function ($$v) {
                                          _vm.workingHoursForPersonalUse = $$v
                                        },
                                        expression:
                                          "workingHoursForPersonalUse",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center my-2" },
                      [
                        _c("ui-radio", {
                          attrs: {
                            value: _vm.workingHoursType,
                            option: _vm.WORKING_HOURS_TYPES.scheduled,
                            name: "ScheduleInput",
                            caption: "Scheduled",
                            "data-test-id": "schedule-input",
                          },
                          on: { changevalue: _vm.setWorkingHoursScheduled },
                        }),
                        _c(
                          "ui-tooltip",
                          {
                            staticClass: "ml-1",
                            attrs: {
                              placement: _vm.PLACEMENTS.right,
                              tooltip:
                                "Reserved for employees on selected days and hours",
                            },
                          },
                          [
                            _c("ui-icon", {
                              attrs: {
                                icon: _vm.ICONS.infoFull,
                                color: _vm.GRAYSCALE.inkLight,
                                size: _vm.ICONS_SIZES.medium,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.workingHoursType === _vm.WORKING_HOURS_TYPES.scheduled
                      ? _c("div", { staticClass: "py-3" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-block emobg-border-1 p-3 emobg-border-color-ground-light emobg-border-radius-large",
                            },
                            [
                              _c("h3", [_vm._v("Schedule working hours")]),
                              _c("p", { staticClass: "mt-2" }, [
                                _vm._v(
                                  " Hours when this fleet segment will be dedicated to employees only. "
                                ),
                              ]),
                              _c("WeekSchedule", {
                                staticClass: "mt-3",
                                attrs: {
                                  "day-names": _vm.dayNames,
                                  "is-disabled": !!_vm.editFleetSegment,
                                },
                                model: {
                                  value: _vm.workingHours,
                                  callback: function ($$v) {
                                    _vm.workingHours = $$v
                                  },
                                  expression: "workingHours",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center my-2" },
                      [
                        _c("ui-radio", {
                          attrs: {
                            value: _vm.workingHoursType,
                            option: _vm.WORKING_HOURS_TYPES.notDedicated,
                            name: "never",
                            caption: "Not dedicated",
                            "data-test-id": "not_dedicated-input",
                          },
                          on: { changevalue: _vm.setWorkingHoursNotDedicated },
                        }),
                        _c(
                          "ui-tooltip",
                          {
                            staticClass: "ml-1",
                            attrs: {
                              placement: _vm.PLACEMENTS.right,
                              tooltip:
                                "Available for both employees and open users too",
                            },
                          },
                          [
                            _c("ui-icon", {
                              attrs: {
                                icon: _vm.ICONS.infoFull,
                                color: _vm.GRAYSCALE.inkLight,
                                size: _vm.ICONS_SIZES.medium,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "FormCollapseSection",
            {
              staticClass: "pb-3",
              attrs: {
                title:
                  "Do you need specific booking rules for this fleet segment?",
              },
            },
            [
              _vm.isBookingRulesReady
                ? _c("FleetSegmentBookingRules", {
                    staticClass: "px-3 pt-3",
                    attrs: {
                      "initial-rules": _vm.get(
                        _vm.editFleetSegment,
                        "bookingRules",
                        []
                      ),
                      "is-mixed-use":
                        _vm.workingHoursType ===
                        _vm.WORKING_HOURS_TYPES.scheduled,
                    },
                    on: {
                      isValid: (isValid) =>
                        (_vm.isBookingRulesFormValid = isValid),
                      change: (value) => (_vm.bookingRules = value),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "emobg-border-1 emobg-border-color-ground-light position-sticky z-index-100 bottom-0 bg-color-white p-3 d-flex justify-content-end align-items-center",
          staticStyle: { bottom: "0" },
        },
        [
          _c("CancelButton", {
            staticClass: "mr-2",
            attrs: { "data-test-id": "cancel-button" },
            on: { click: _vm.goToFleetSegments },
          }),
          _c(
            "ui-button",
            {
              attrs: {
                disabled: _vm.isCreateDisabled,
                loading: _vm.isLoading,
                "data-test-id": "save-button",
              },
              on: { clickbutton: _vm.saveFleetSegment },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.editFleetSegment
                      ? _vm.$t("Common.Actions.save")
                      : _vm.$t("Common.Actions.create")
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
      _vm.isErrorModalVisible
        ? _c(
            "FleetSegmentErrorModal",
            _vm._b(
              {
                attrs: { vehicles: _vm.errorVehicles },
                on: {
                  "modal-closed": function ($event) {
                    _vm.isErrorModalVisible = false
                  },
                },
              },
              "FleetSegmentErrorModal",
              _vm.errorModalLabels,
              false
            )
          )
        : _vm._e(),
      _vm.isScheduleModalVisible
        ? _c("FleetSegmentScheduleModal", {
            on: {
              "close-modal": function ($event) {
                _vm.isScheduleModalVisible = false
              },
            },
          })
        : _vm._e(),
      _vm.hasConflictingBookingRules
        ? _c("ConflictingBookingRulesModal", {
            on: {
              "modal-closed": function ($event) {
                _vm.hasConflictingBookingRules = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }