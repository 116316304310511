<script>
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import head from 'lodash/head';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import join from 'lodash/join';
import flatMap from 'lodash/flatMap';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import reject from 'lodash/reject';
import remove from 'lodash/remove';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { navigationErrorHandler } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import crm from '@/domains/CRM/router/CRMRouterMap';
import { CancelButton, WeekSchedule } from '@/components';
import { scopes as fleetSegmentScopes } from '../../../store/FleetSegmentModule';
import FleetSegmentErrorModal from '../FleetSegmentErrorModal/FleetSegmentErrorModal';
import FleetSegmentScheduleModal from '../FleetSegmentScheduleModal/FleetSegmentScheduleModal';
import { contentCells, queryParameters, vehicleFacets } from './config/contentCells';
import FleetSegmentBookingRules from './FleetSegmentBookingRules/FleetSegmentBookingRules';
import FormCollapseSection from '../components/FormCollapseSection';
import ConflictingBookingRulesModal from './components/ConflictingBookingRulesModal';

import {
  EMPTY_WORKING_HOURS,
  FULL_TIME_WORKING_HOURS,
  WORKING_HOURS_TYPES,
} from './constants';
import { FLEET_SEGMENT_ERRORS, FLEET_SEGMENT_SCOPES } from '../../../const/fleetSegment';
import { CRM_SEGMENT } from '../../../../const/segments.const';
import { COMPANY_SCOPES } from '../../../store/CompanyModule';

export default {
  components: {
    CancelButton,
    ConflictingBookingRulesModal,
    FleetSegmentBookingRules,
    FleetSegmentErrorModal,
    FleetSegmentScheduleModal,
    MuiAlgoliaList,
    WeekSchedule,
    FormCollapseSection,
  },
  beforeRouteLeave(_to, _from, next) {
    // Remove it from the store so when we enter again it will not appear the old fleet segment
    this.setFleetSegment({
      scope: fleetSegmentScopes.fleetSegment,
      value: null,
    });
    next();
  },
  props: {
    fleetSegmentUuid: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      name: '',
      description: '',
      vehicles: [],
      filteredVehicles: false,
      isSchedule: false,
      workingHoursType: WORKING_HOURS_TYPES.always,
      workingHours: FULL_TIME_WORKING_HOURS,
      workingHoursForPersonalUse: EMPTY_WORKING_HOURS,
      isErrorModalVisible: false,
      isScheduleModalVisible: false,
      allowPersonalUseOfDedicated: false,
      bookingRules: [],
      isBookingRulesFormValid: true,
      hasConflictingBookingRules: false,
      hasFormBeenChanged: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeUserUuid: state => get(state, 'user.data.uuid'),
      operatorTimezone: state => get(state, 'operators.active.timezone'),
    }),
    ...mapState(DOMAINS_MODEL.crm.fleetSegment, {
      editFleetSegment: state => get(state, 'fleetSegment.data.data'),
      isError: state => get(state, 'fleetSegment.STATUS.ERROR'),
      isLoading: state => get(state, 'fleetSegment.STATUS.LOADING'),
      errorVehicles: state => get(state, 'fleetSegment.error.data.vehicles'),
      errorKey: state => get(state, 'fleetSegment.error.key'),
    }),
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      companyOperatorUuid: state => get(state, 'company.data.csOperator.uuid'),
      companyDedicatedOperatorUuid: state => get(state, 'company.data.dedicatedFleetCsOperatorUuid'),
    }),
    isEditMode() {
      return !isNil(this.fleetSegmentUuid);
    },
    contentCellsComputed() {
      return contentCells({ vehicles: this.vehicles });
    },
    algoliaFilter() {
      const vehicles = map(this.vehicles, uuid => `uuid:${uuid}`);
      const vehiclesFilter = `${join(vehicles, ' OR ')}`;

      const operatorFilter = this.companyDedicatedOperatorUuid
        ? `dedicated_cs_operator_uuid:${this.companyDedicatedOperatorUuid}`
        : `cs_operator_uuid:${this.companyOperatorUuid}`;

      return this.filteredVehicles && !isEmpty(vehicles)
        ? `${vehiclesFilter} AND ${operatorFilter}`
        : operatorFilter;
    },
    filtersLabel() {
      return this.filteredVehicles
        ? this.$t('CRM.Companies.FleetSegments.create.seeAllVehicles')
        : `${this.$t('CRM.Companies.FleetSegments.create.seeAssignedVehicles')} (${this.vehicles.length})`;
    },
    title() {
      return this.editFleetSegment
        ? this.$t('CRM.Companies.FleetSegments.edit.title')
        : this.$t('CRM.Companies.FleetSegments.create.title');
    },
    errorModalLabels() {
      const action = this.errorKey === FLEET_SEGMENT_ERRORS.vehiclesCannotBeAdded ? 'add' : 'remove';
      return {
        title: this.$t(`CRM.Companies.FleetSegments.create.vehicleError.${action}.title`),
        message: this.$t(`CRM.Companies.FleetSegments.create.vehicleError.${action}.message`),
        actionText: this.$t('CRM.Companies.FleetSegments.create.vehicleError.actionText'),
      };
    },
    currentEvent() {
      return pickBy({
        type: this.editFleetSegment ? FLEET_SEGMENT_SCOPES.edit : FLEET_SEGMENT_SCOPES.create,
        companyUuid: this.company.uuid,
        fleetSegmentUuid: get(this, 'editFleetSegment.uuid'),
        name: this.name,
        userUuid: this.activeUserUuid,
      }, identity);
    },
    hasScheduleAlways() {
      const fullDaySchedule = '00:00-24:00';
      const scheduleDaysFilled = reject(this.workingHours, day => head(day) === fullDaySchedule);
      const scheduleDaysEmpty = reject(this.workingHours, day => isEqual(day, []));
      return isEmpty(scheduleDaysFilled) || isEmpty(scheduleDaysEmpty);
    },
    isBookingRulesReady() {
      return this.fleetSegmentUuid ? this.companyOperatorUuid && this.editFleetSegment : this.companyOperatorUuid;
    },
    hasScheduleData() {
      const scheduleDaysEmpty = reject(this.workingHours, day => isEqual(day, []));
      const personalUseScheduleDaysEmpty = reject(this.workingHoursForPersonalUse, day => isEqual(day, []));

      const hasEmptyDays = !(!this.allowPersonalUseOfDedicated && isEmpty(scheduleDaysEmpty));
      const hasEmptyDaysForPersonalUse = !(this.allowPersonalUseOfDedicated && isEmpty(personalUseScheduleDaysEmpty));

      return this.workingHoursType === WORKING_HOURS_TYPES.notDedicated || (hasEmptyDays && hasEmptyDaysForPersonalUse);
    },
    isCreateDisabled() {
      return !this.vehicles.length || !this.hasFormBeenChanged || !this.isBookingRulesFormValid || !this.hasScheduleData;
    },
  },
  watch: {
    editFleetSegment: {
      deep: true,
      handler() {
        if (this.hasFormBeenChanged) {
          this.updateFleetSegmentData();
        }
      },
    },
    vehicles: {
      handler(vehicles) {
        if (isEmpty(vehicles) && this.filteredVehicles) {
          this.filteredVehicles = false;
        }
      },
    },
  },
  async created() {
    this.WORKING_HOURS_TYPES = WORKING_HOURS_TYPES;
    this.CRM_SEGMENT = CRM_SEGMENT;
    this.updateFleetSegmentData();
    this.$watch(instance => [
      instance.name,
      instance.description,
      instance.vehicles,
      instance.workingHoursType,
      instance.workingHours,
    ], () => {
      this.hasFormBeenChanged = true;
    }, { deep: true });
    this.errorMapping = {
      [FLEET_SEGMENT_ERRORS.vehiclesCannotBeAdded]: this.CRM_SEGMENT.FLEET_SEGMENT.vehiclesCannotBeAdded,
      [FLEET_SEGMENT_ERRORS.vehiclesCannotBeRemoved]: this.CRM_SEGMENT.FLEET_SEGMENT.vehiclesCannotBeRemoved,
    };
    this.queryParameters = queryParameters;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.algoliaFacets = vehicleFacets.bind(this)();
    this.algoliaActions = [
      {
        label: this.$t('CRM.Companies.FleetSegments.create.selectAll'),
        class: 'emobg-color-primary',
        method: this.selectAllVehicles,
      },
      {
        label: this.$t('CRM.Companies.FleetSegments.create.clearSelected'),
        class: 'emobg-color-danger',
        method: this.removeVehicles,
      },
    ];
    this.dayNames = {
      monday: this.$t('Common.Time.monday'),
      tuesday: this.$t('Common.Time.tuesday'),
      wednesday: this.$t('Common.Time.wednesday'),
      thursday: this.$t('Common.Time.thursday'),
      friday: this.$t('Common.Time.friday'),
      saturday: this.$t('Common.Time.saturday'),
      sunday: this.$t('Common.Time.sunday'),
    };

    if (this.isEditMode) {
      await this.getFleetSegment({ fleetSegmentUuid: this.fleetSegmentUuid });
    }

    this.updateFleetSegmentData();
  },
  methods: {
    get,
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    ...mapMutations(DOMAINS_MODEL.crm.fleetSegment, {
      setFleetSegment: 'setData',
    }),
    ...mapActions(DOMAINS_MODEL.crm.fleetSegment, [
      'getFleetSegment',
      'patchFleetSegment',
      'postFleetSegment',
    ]),
    getWorkingHoursType(editFleetSegment) {
      if (isEmpty(flatMap(editFleetSegment.workingHours.days))) {
        return WORKING_HOURS_TYPES.notDedicated;
      }

      if (!editFleetSegment.allowPersonalDedicated) {
        return WORKING_HOURS_TYPES.always;
      }
      return this.isAlwaysWorkingHours(editFleetSegment.workingHours.days) ? WORKING_HOURS_TYPES.always : WORKING_HOURS_TYPES.scheduled;
    },
    isAlwaysWorkingHours(workingHours) {
      return isEqual(workingHours, FULL_TIME_WORKING_HOURS);
    },
    updateFleetSegmentData() {
      if (this.editFleetSegment) {
        this.workingHours = get(this.editFleetSegment, 'workingHours.days');
        this.name = this.editFleetSegment.name;
        this.description = this.editFleetSegment.description;
        this.vehicles = map(this.editFleetSegment.vehicles, 'uuid');
        this.bookingRules = this.editFleetSegment.bookingRules;
        this.workingHoursType = this.getWorkingHoursType(this.editFleetSegment);

        if (this.workingHoursType === WORKING_HOURS_TYPES.always && !get(this.editFleetSegment, 'allowPersonalDedicated')) {
          this.allowPersonalUseOfDedicated = true;
          this.workingHoursForPersonalUse = cloneDeep(get(this.editFleetSegment, 'workingHours.days'));
        }
      }
    },
    removeVehicle(uuid) {
      const index = findIndex(this.vehicles, { uuid });
      this.vehicles.splice(index, 1);
    },
    removeVehicles() {
      remove(this.vehicles);
      this.vehicles.splice();
      this.filteredVehicles = false;
    },
    switchAlgoliaView() {
      this.filteredVehicles = !this.filteredVehicles;
    },
    goToFleetSegments() {
      this.$router.push({ name: crm.companies.detail.fleetSegments.index }).catch(navigationErrorHandler);
    },
    selectAllVehicles() {
      const vehicles = get(this.$refs, 'vehiclesList.mainSearchStore._results');
      this.vehicles = map(vehicles, ({ id, license_plate: licensePlate, uuid }) => ({ id, licensePlate, uuid }));
    },
    setWorkingHoursAlways() {
      this.workingHours = cloneDeep(FULL_TIME_WORKING_HOURS);
      this.workingHoursType = WORKING_HOURS_TYPES.always;
      this.allowPersonalUseOfDedicated = false;
    },
    setWorkingHoursScheduled() {
      this.workingHours = cloneDeep(EMPTY_WORKING_HOURS);
      this.workingHoursType = WORKING_HOURS_TYPES.scheduled;
      this.allowPersonalUseOfDedicated = false;
      this.isScheduleModalVisible = true;
    },
    setWorkingHoursFotPersonalUse({ detail: allowPersonalUse }) {
      this.workingHours = cloneDeep(FULL_TIME_WORKING_HOURS);
      if (!this.editFleetSegment) {
        this.workingHoursForPersonalUse = cloneDeep(EMPTY_WORKING_HOURS);
      }
      this.allowPersonalUseOfDedicated = allowPersonalUse;
    },
    setWorkingHoursNotDedicated() {
      this.workingHours = cloneDeep(EMPTY_WORKING_HOURS);
      this.allowPersonalUseOfDedicated = false;
      this.workingHoursType = WORKING_HOURS_TYPES.notDedicated;
    },
    async saveFleetSegment() {
      if (this.workingHoursType === WORKING_HOURS_TYPES.scheduled && this.isAlwaysWorkingHours(this.workingHours)) {
        this.workingHoursType = WORKING_HOURS_TYPES.always;
        this.workingHours = FULL_TIME_WORKING_HOURS;
      }

      let { workingHours } = this;

      if (!this.allowPersonalUseOfDedicated && this.workingHoursType === WORKING_HOURS_TYPES.always) {
        workingHours = FULL_TIME_WORKING_HOURS;
      } else if (this.allowPersonalUseOfDedicated && this.workingHoursType === WORKING_HOURS_TYPES.always) {
        workingHours = this.workingHoursForPersonalUse;
      }

      const payload = {
        name: this.name,
        description: this.description,
        // We are sending the reverse of the toggle true/false to show it activated but sending the opposite value
        allowPersonalDedicated: !this.allowPersonalUseOfDedicated,
        workingHours: {
          days: this.workingHoursType !== WORKING_HOURS_TYPES.notDedicated ? workingHours : [],
          timeZone: get(this.editFleetSegment, 'workingHours.timeZone') || this.operatorTimezone,
        },
        vehicles: map(this.vehicles, uuid => ({ uuid })),
        companyUuid: this.company.uuid,
        bookingRules: this.bookingRules,
      };
      if (this.editFleetSegment) {
        await this.patchFleetSegment({
          uuid: this.fleetSegmentUuid,
          fleetSegment: payload,
        });
      } else {
        await this.postFleetSegment(payload);
      }
      if (!this.isError) {
        this.notify({
          message: this.isEditMode
            ? this.$t('CRM.Companies.FleetSegments.edit.successMessage')
            : this.$t('CRM.Companies.FleetSegments.create.successMessage'),
        });
        this.$router.push({ name: crm.companies.detail.fleetSegments.index }).catch(navigationErrorHandler);
      }

      if (this.isError && this.errorVehicles) {
        const errorHandlers = {
          [FLEET_SEGMENT_ERRORS.conflictingBookingRules]: () => {
            this.hasConflictingBookingRules = true;
          },
          default: () => {
            this.isErrorModalVisible = true;
          },
        };
        const errorHandler = get(errorHandlers, this.errorKey, errorHandlers.default);
        errorHandler();
      }
    },
  },
};
</script>
<template>
  <div
    class="mb-4"
    data-test-id="fleet_segment-form"
  >
    <h1 class="mb-3">
      {{ title }}
    </h1>
    <div
      class="
        emobg-background-color-white emobg-border-top-1
        emobg-border-left-1 emobg-border-right-1 emobg-border-color-ground-light
        mt-2 position-relative
      "
    >
      <div class="emobg-background-color-ground-lightest p-3">
        <h2>
          {{
            editFleetSegment
              ? $t('CRM.Companies.FleetSegments.edit.subtitle')
              : $t('CRM.Companies.FleetSegments.create.subtitle')
          }}
        </h2>
      </div>
      <div class="p-3 mb-2">
        <ui-text-input
          :value="name"
          :placeholder="$t('CRM.Companies.FleetSegments.create.inputs.namePlaceholder')"
          :label="$t('CRM.Companies.FleetSegments.create.inputs.nameLabel')"
          name="name"
          data-test-id="name-input"
          class="my-3"
          @changevalue="({ detail }) => name = detail"
        />
        <ui-text-area
          :value="description"
          :label="$t('CRM.Companies.FleetSegments.create.inputs.commentsLabel')"
          :placeholder="$t('CRM.Companies.FleetSegments.create.inputs.commentsPlaceholder')"
          name="comments"
          rows="4"
          class="mt-3"
          data-test-id="comments-input"
          @changevalue="({ detail }) => description = detail"
        />
      </div>

      <FormCollapseSection
        title="Vehicles"
        class="pb-3"
      >
        <div :class="['p-3', { 'disabled': !name }]">
          <div class="d-flex align-items-center justify-content-between my-4">
            <div>
              <h3 class="mb-1">
                {{
                  editFleetSegment
                    ? $t('CRM.Companies.FleetSegments.edit.addVehicles')
                    : $t('CRM.Companies.FleetSegments.create.addVehicles')
                }}
              </h3>
              <div>
                {{ $t('CRM.Companies.FleetSegments.create.addVehiclesDescription') }}
              </div>
            </div>
            <ui-button
              :disabled="!vehicles.length"
              data-test-id="switch_view-button"
              @clickbutton="switchAlgoliaView"
            >
              {{ filtersLabel }}
            </ui-button>
          </div>
          <MuiAlgoliaList
            ref="vehiclesList"
            :index="ALGOLIA_INDEXES.vehicles"
            :filters="algoliaFilter"
            :table-config="contentCellsComputed"
            :query-parameters="queryParameters"
            :actions="algoliaActions"
            :facets="algoliaFacets"
            is-export-enabled
          />
        </div>
      </FormCollapseSection>

      <FormCollapseSection
        :title="$t('CRM.Companies.FleetSegments.create.scheduleTitle')"
        class="pb-3"
      >
        <div :class="['p-3', { 'disabled': !name }]">
          <div :class="{ 'disabled': !!editFleetSegment }">
            <div class="my-2">
              <div class="d-flex align-items-center my-2">
                <ui-radio
                  :value="workingHoursType"
                  :option="WORKING_HOURS_TYPES.always"
                  caption="Always dedicated"
                  name="always"
                  data-test-id="always-input"
                  @changevalue="setWorkingHoursAlways"
                />
                <ui-tooltip
                  :placement="PLACEMENTS.right"
                  tooltip="Reserved for employees only"
                  class="ml-1"
                >
                  <ui-icon
                    :icon="ICONS.infoFull"
                    :color="GRAYSCALE.inkLight"
                    :size="ICONS_SIZES.medium"
                  />
                </ui-tooltip>
              </div>
              <div
                v-if="company.personalDedicatedUse"
                :class="[{ 'disabled': workingHoursType !== WORKING_HOURS_TYPES.always }]"
                class="PersonalUseOfDedicated d-block emobg-border-1 p-3 emobg-border-color-ground-light emobg-border-radius-large"
              >
                <ui-toggle
                  :value="allowPersonalUseOfDedicated"
                  text="Restrict the access of this Fleet Segment to employees with Personal Profile inside working hours"
                  data-test-id="personal-use-dedicated"
                  name="activityToggle"
                  class="d-block"
                  @changevalue="setWorkingHoursFotPersonalUse"
                />
                <div
                  v-if="allowPersonalUseOfDedicated"
                  :class="[{ 'disabled': workingHoursType !== WORKING_HOURS_TYPES.always }]"
                  class="d-block emobg-border-1 mt-3 p-3 emobg-border-color-ground-light emobg-border-radius-large"
                >
                  <h3>Schedule working hours</h3>
                  <p class="mt-2">
                    Hours when the fleet will be dedicated to employees with Business profile only.
                  </p>
                  <WeekSchedule
                    v-model="workingHoursForPersonalUse"
                    :day-names="dayNames"
                    :is-disabled="!!editFleetSegment"
                    class="mt-3"
                  />
                </div>
              </div>
              <div class="d-flex align-items-center my-2">
                <ui-radio
                  :value="workingHoursType"
                  :option="WORKING_HOURS_TYPES.scheduled"
                  name="ScheduleInput"
                  caption="Scheduled"
                  data-test-id="schedule-input"
                  @changevalue="setWorkingHoursScheduled"
                />
                <ui-tooltip
                  :placement="PLACEMENTS.right"
                  tooltip="Reserved for employees on selected days and hours"
                  class="ml-1"
                >
                  <ui-icon
                    :icon="ICONS.infoFull"
                    :color="GRAYSCALE.inkLight"
                    :size="ICONS_SIZES.medium"
                  />
                </ui-tooltip>
              </div>
              <div
                v-if="workingHoursType === WORKING_HOURS_TYPES.scheduled"
                class="py-3"
              >
                <div class="d-block emobg-border-1 p-3 emobg-border-color-ground-light emobg-border-radius-large">
                  <h3>Schedule working hours</h3>
                  <p class="mt-2">
                    Hours when this fleet segment will be dedicated to employees only.
                  </p>
                  <WeekSchedule
                    v-model="workingHours"
                    :day-names="dayNames"
                    :is-disabled="!!editFleetSegment"
                    class="mt-3"
                  />
                </div>
              </div>
              <div class="d-flex align-items-center my-2">
                <ui-radio
                  :value="workingHoursType"
                  :option="WORKING_HOURS_TYPES.notDedicated"
                  name="never"
                  caption="Not dedicated"
                  data-test-id="not_dedicated-input"
                  @changevalue="setWorkingHoursNotDedicated"
                />
                <ui-tooltip
                  :placement="PLACEMENTS.right"
                  tooltip="Available for both employees and open users too"
                  class="ml-1"
                >
                  <ui-icon
                    :icon="ICONS.infoFull"
                    :color="GRAYSCALE.inkLight"
                    :size="ICONS_SIZES.medium"
                  />
                </ui-tooltip>
              </div>
            </div>
          </div>
        </div>
      </FormCollapseSection>

      <FormCollapseSection
        title="Do you need specific booking rules for this fleet segment?"
        class="pb-3"
      >
        <FleetSegmentBookingRules
          v-if="isBookingRulesReady"
          :initial-rules="get(editFleetSegment, 'bookingRules', [])"
          :is-mixed-use="workingHoursType === WORKING_HOURS_TYPES.scheduled"
          class="px-3 pt-3"
          @isValid="isValid => isBookingRulesFormValid = isValid"
          @change="value => bookingRules = value"
        />
      </FormCollapseSection>
    </div>
    <div
      class="
        emobg-border-1 emobg-border-color-ground-light position-sticky z-index-100
        bottom-0 bg-color-white p-3 d-flex justify-content-end align-items-center
      "
      style="bottom: 0;"
    >
      <CancelButton
        class="mr-2"
        data-test-id="cancel-button"
        @click="goToFleetSegments"
      />
      <ui-button
        :disabled="isCreateDisabled"
        :loading="isLoading"
        data-test-id="save-button"
        @clickbutton="saveFleetSegment"
      >
        {{ editFleetSegment ? $t('Common.Actions.save') : $t('Common.Actions.create') }}
      </ui-button>
    </div>
    <FleetSegmentErrorModal
      v-if="isErrorModalVisible"
      v-bind="errorModalLabels"
      :vehicles="errorVehicles"
      @modal-closed="isErrorModalVisible = false"
    />
    <FleetSegmentScheduleModal
      v-if="isScheduleModalVisible"
      @close-modal="isScheduleModalVisible = false"
    />
    <ConflictingBookingRulesModal
      v-if="hasConflictingBookingRules"
      @modal-closed="hasConflictingBookingRules = false"
    />
  </div>
</template>
