<script>
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import toNumber from 'lodash/toNumber';
import toString from 'lodash/toString';
import xorWith from 'lodash/xorWith';
import { mapActions, mapState } from 'vuex';
import {
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { PRECISION } from '@emobg/web-utils';
import { BOOKING_TYPES } from '@domains/Carsharing/const/bookingTypes';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { BOOKING_RULES } from '../../../../const/fleetSegment';
import FleetSegmentBookingRuleInput from './FleetSegmentBookingRuleInput/FleetSegmentBookingRuleInput';

export default {
  name: 'FleetSegmentBookingRules',
  components: {
    FleetSegmentBookingRuleInput,
    MuiValidationWrapper,
  },
  directives: { Validate },
  props: {
    initialRules: {
      type: Array,
      default: () => [],
    },
    isMixedUse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCustomBookingRules: false,
      bookingRules: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      companyConfig: state => get(state, 'config.data'),
    }),
    ...mapState(DOMAINS_MODEL.crm.fleetSegment, {
      isEditing: state => !isEmpty(get(state, 'fleetSegment.data.data')),
    }),
    sortedBookingRulesByType() {
      const types = [BOOKING_TYPES.carsharing, BOOKING_TYPES.longDistance, null];
      return reduce(types, (rulesByType, type) => {
        const rules = filter(this.bookingRules, { type });
        if (rules.length) {
          const translationTypeKey = type || 'general';
          rulesByType.push({
            title: this.$t(`CRM.Companies.FleetSegments.bookingRules.form.sections.${translationTypeKey}.title`),
            description: this.$t(`CRM.Companies.FleetSegments.bookingRules.form.sections.${translationTypeKey}.description`),
            rules,
          });
        }
        return rulesByType;
      }, []);
    },
    bookingRulesToSend() {
      const iteration = (accumulator, bookingRule) => {
        if (!isEmpty(bookingRule.value) || isNumber(bookingRule.value)) {
          accumulator.push(pick(bookingRule, ['rule', 'type', 'value']));
        }
        return accumulator;
      };
      return reduce(this.bookingRules, iteration, []);
    },
    areCustomBookingRulesSelectedAndEmpty() {
      return this.isCustomBookingRules && isEmpty(this.bookingRulesToSend);
    },
    showChangedValueAlert() {
      const areBookingRulesDifferentFromOriginal = !isEmpty(xorWith(
        this.originalBookingRules,
        this.bookingRules,
        (originalBookingRule, actualBookingRule) => {
          const isSameRule = originalBookingRule.rule === actualBookingRule.rule;
          const isSameType = originalBookingRule.type === actualBookingRule.type;
          const isSameValue = (originalBookingRule.value === actualBookingRule.value)
            || (originalBookingRule.isSelect && isEmpty(toString(originalBookingRule.value))
              && actualBookingRule.isSelect && isEmpty(toString(actualBookingRule.value)));
          return isSameRule && isSameType && isSameValue;
        },
      ));
      const isCustomBookingRulesDifferentFromOriginal = this.isCustomBookingRules !== !!this.initialRules.length;
      return this.isEditing && (areBookingRulesDifferentFromOriginal || isCustomBookingRulesDifferentFromOriginal);
    },
  },
  watch: {
    bookingRulesToSend(value) {
      this.$emit('change', value);
    },
    isCustomBookingRules(value) {
      if (!value) {
        this.createBookingRules();
      }
    },
    showChangedValueAlert(value) {
      this.$nextTick(() => {
        if (value && !this.alertShowed) {
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const alertComputeds = window.getComputedStyle(this.$refs.alert.$el);
          const marginBottom = toNumber(alertComputeds.marginBottom.match(/\d+/));
          // Not move the scroll when appears the alert to prevent weird scroll jumps
          window.scroll({ top: scrollTop + this.$refs.alert.$el.clientHeight + marginBottom });

          this.alertShowed = true;
          // 100 = 80 margin of navbar + 20 to make a separation
          const alertOffset = this.$refs.alert.$el.getBoundingClientRect().top
            + this.$refs.alert.$el.ownerDocument.defaultView.pageYOffset - 100;

          const isNotVisible = scrollTop > alertOffset + this.$refs.alert.$el.clientHeight;
          if (isNotVisible) {
            window.scroll({ top: alertOffset, behavior: 'smooth' });
          }
        }
      });
    },
  },
  async created() {
    this.alertShowed = false;
    this.isCustomBookingRules = !!this.initialRules.length;

    if (!this.companyConfig) {
      await this.getCompanyConfig();
    }

    this.allBookingRules = [
      {
        rule: BOOKING_RULES.minDurationMinutes,
        type: BOOKING_TYPES.carsharing,
        unitTime: PRECISION.hours,
      },
      {
        rule: BOOKING_RULES.maxDurationMinutes,
        type: BOOKING_TYPES.carsharing,
        unitTime: PRECISION.hours,
      },
      {
        isHidden: !get(this.companyConfig, 'longDistanceAllowed', 0),
        rule: BOOKING_RULES.minDurationMinutes,
        type: BOOKING_TYPES.longDistance,
        unitTime: PRECISION.days,
      },
      {
        isHidden: !get(this.companyConfig, 'longDistanceAllowed', 0),
        rule: BOOKING_RULES.maxDurationMinutes,
        type: BOOKING_TYPES.longDistance,
        unitTime: PRECISION.days,
      },
      {
        isSelect: true,
        rule: BOOKING_RULES.bookingGap,
        type: null,
        unitTime: PRECISION.minutes,
      },
      {
        isHidden: !get(this.companyConfig, 'allowAutoCancel', 0),
        isSelect: true,
        rule: BOOKING_RULES.autoCancelMinutes,
        type: null,
        unitTime: PRECISION.minutes,
      },
      {
        rule: BOOKING_RULES.maxFutureBookingMinutes,
        type: null,
        unitTime: PRECISION.days,
      },
    ];

    this.createBookingRules();
    this.originalBookingRules = cloneDeep(this.bookingRules);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'getCompanyConfig',
    ]),
    createBookingRules() {
      const reduceBookingRules = (allowedBookingRules, bookingRule) => {
        if (!bookingRule.isHidden) {
          const ruleProvidedFound = find(this.initialRules, { type: bookingRule.type, rule: bookingRule.rule });
          const defaultValue = bookingRule.isSelect ? null : '';
          const ruleValue = get(ruleProvidedFound, 'value', defaultValue);
          allowedBookingRules.push({
            ...bookingRule,
            value: this.isCustomBookingRules ? ruleValue : defaultValue,
          });
        }
        return allowedBookingRules;
      };
      this.bookingRules = reduce(this.allBookingRules, reduceBookingRules, []);
    },
  },
};
</script>

<template>
  <MuiValidationWrapper
    class="FleetSegmentBookingRules"
    :extra-conditions="[!areCustomBookingRulesSelectedAndEmpty]"
    @areAllValid="isValid => $emit('isValid', isValid)"
  >
    <div class="emobg-font-default emobg-font-line-height-large">
      <div class="ml-2 mb-3">
        <div>
          <ui-radio
            :value="isCustomBookingRules"
            :option="false"
            :caption="$t('CRM.Companies.FleetSegments.bookingRules.form.options.no.text')"
            class="mb-3"
            name="customBookingRules"
            @changevalue="() => isCustomBookingRules = false"
          />
          <ui-tooltip :tooltip="$t('CRM.Companies.FleetSegments.bookingRules.form.options.no.tooltip')">
            <ui-icon
              :icon="ICONS.infoFull"
              :size="SIZES.medium"
              :color="GRAYSCALE.inkLight"
              class="emobg-color-ink-hover"
            />
          </ui-tooltip>
        </div>
        <ui-radio
          :value="isCustomBookingRules"
          :option="true"
          :caption="$t('CRM.Companies.FleetSegments.bookingRules.form.options.yes.text')"
          name="customBookingRules"
          @changevalue="() => isCustomBookingRules = true"
        />
      </div>
      <p
        class="d-inline-block mb-5 p-2 emobg-background-color-ground-lightest emobg-border-radius-small"
        style="max-width: 623px;"
        v-html="isMixedUse
          ? $t('CRM.Companies.FleetSegments.bookingRules.form.infoMixedUse.withMixedUse')
          : $t('CRM.Companies.FleetSegments.bookingRules.form.infoMixedUse.withoutMixedUse')"
      />
      <ui-alert
        v-if="showChangedValueAlert"
        ref="alert"
        :color="COLORS.primary"
        :icon="ICONS.infoFull"
        class="w-100 mb-3 d-block"
      >
        <span class="emobg-caption-1">
          {{ $t(`CRM.Companies.FleetSegments.bookingRules.form.changedValuesAlert`) }}
        </span>
      </ui-alert>
    </div>

    <template v-if="isCustomBookingRules">
      <div
        v-for="(bookingRulesByType, index) in sortedBookingRulesByType"
        :key="index"
        :class="{ 'mb-2': index + 1 < sortedBookingRulesByType.length }"
      >
        <h4 class="emobg-font-weight-semibold">
          {{ bookingRulesByType.title }}
        </h4>
        <p class="emobg-body-1">
          {{ bookingRulesByType.description }}
        </p>
        <div class="row mt-2 mb-n3">
          <div
            v-for="rule in bookingRulesByType.rules"
            :key="`${rule.type}-${rule.rule}`"
            class="col-sm-12 col-md-6 mb-3"
          >
            <FleetSegmentBookingRuleInput
              v-model="rule.value"
              :description="$t(`CRM.Companies.FleetSegments.bookingRules.rules.${rule.rule}.description`)"
              :is-select="rule.isSelect"
              :title="$t(`CRM.Companies.FleetSegments.bookingRules.rules.${rule.rule}.title`)"
              :unit-time="rule.unitTime"
            />
          </div>
        </div>
      </div>
    </template>
  </MuiValidationWrapper>
</template>
