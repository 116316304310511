<script>
import get from 'lodash/get';
import includes from 'lodash/includes';
import { mapActions, mapMutations, mapState } from 'vuex';
import { DELAY, navigationErrorHandler } from '@emobg/web-utils';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import crm from '@domains/CRM/router/CRMRouterMap';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import { getNoResultsLabel, refreshAlgoliaStore } from '@/utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { contentCells } from './config/contentCells';
import DeleteFleetSegment from './DeleteFleetSegment';
import FleetSegmentErrorModal from './FleetSegmentErrorModal/FleetSegmentErrorModal';
import { FLEET_SEGMENT_ERRORS } from '../../const/fleetSegment';
import { COMPANY_SCOPES } from '../../store/CompanyModule';

export default {
  name: 'CompanyFleetSegmentsListView',
  components: {
    DeleteFleetSegment,
    MuiAlgoliaList,
    FleetSegmentErrorModal,
  },
  data() {
    return {
      isDeleteModalOpen: false,
      isDeleteErrorModalOpen: false,
      removableFleetSegment: undefined,
      algoliaItemActions: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.fleetSegment, {
      isError: state => get(state, 'fleetSegment.STATUS.ERROR'),
      errorVehicles: state => get(state, 'fleetSegment.error.data.vehicles'),
      errorKey: state => get(state, 'fleetSegment.error.key'),
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeUserUuid: state => get(state, 'user.data.uuid'),
      userPermissions: state => get(state, 'user.data.permissions'),
    }),
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      companyConfig: state => get(state, 'config.data'),
      companyOperatorUuid: state => get(state[COMPANY_SCOPES.company], 'data.csOperator.uuid'),
    }),
    hasEditPermission() {
      return includes(this.userPermissions, CRM_PERMISSIONS.manageFleetSegments);
    },
  },
  async created() {
    const element = 'segment';
    this.contentCells = contentCells;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.algoliaFilters = `company.uuid:${this.company.uuid}`;
    if (this.hasEditPermission) {
      this.algoliaItemActions = [
        {
          label: this.$t('Common.Actions.edit_element', { element }),
          method: result => {
            this.$router.push({
              name: crm.companies.detail.fleetSegments.edit,
              params: {
                fleetSegmentUuid: result.uuid,
                companyUuid: this.company.uuid,
              },
            });
          },
        },
        {
          label: `Delete ${element}`,
          type: 'danger',
          class: 'emobg-color-danger',
          method: result => {
            this.removableFleetSegment = result;
            this.isDeleteModalOpen = true;
          },
        },
      ];
    }

    if (!this.companyConfig) {
      await this.getCompanyConfig();
    }
  },
  mounted() {
    this.refreshAlgoliaStore(this.$refs.fleetSegmentsTable, DELAY.extraLong);
  },
  methods: {
    getNoResultsLabel,
    ...mapMutations(DOMAINS_MODEL.app.messages.errors, [
      'throwError',
    ]),
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'getCompanyConfig',
    ]),
    refreshAlgoliaStore,
    createFleetSegment() {
      this.$router.push({ name: crm.companies.detail.fleetSegments.create }).catch(navigationErrorHandler);
    },
    onCloseDeleteModal() {
      this.isDeleteModalOpen = false;
      if (this.isError) {
        if (this.errorKey === FLEET_SEGMENT_ERRORS.vehiclesCannotBeRemoved) {
          this.isDeleteErrorModalOpen = true;
        } else {
          this.throwError({ message: this.$t('CRM.Companies.FleetSegments.delete.errorMessage') });
        }
      } else {
        this.refreshAlgoliaStore(this.$refs.fleetSegmentsTable, DELAY.extraLong);
      }
    },
  },
};
</script>
<template>
  <div
    class="CompanyFleetSegmentsListView"
    data-test-id="company_fleet_segment-view"
  >
    <div class="mb-2 d-flex justify-content-between align-items-center">
      <h1 class="d-flex">
        {{ $t('CRM.Companies.FleetSegments.list.title') }}
        <ui-tooltip
          :tooltip="$t('CRM.Companies.FleetSegments.list.tooltip')"
          class="ml-2"
        >
          <ui-icon
            :icon="ICONS.info"
            :size="ICONS_SIZES.xSmall"
          />
        </ui-tooltip>
      </h1>
      <ui-button
        v-if="hasEditPermission"
        data-test-id="save-button"
        @clickbutton="createFleetSegment"
      >
        Create new fleet segment
      </ui-button>
    </div>
    <MuiAlgoliaList
      ref="fleetSegmentsTable"
      :index="ALGOLIA_INDEXES.fleetSegments"
      :table-config="contentCells"
      :item-actions="algoliaItemActions"
      :filters="algoliaFilters"
      :empty-message="getNoResultsLabel('fleet segments')"
    />
    <DeleteFleetSegment
      v-if="isDeleteModalOpen"
      :fleet-segment-uuid="removableFleetSegment.uuid"
      data-test-id="delete-modal"
      @close-modal="onCloseDeleteModal"
    />
    <FleetSegmentErrorModal
      v-if="isDeleteErrorModalOpen"
      :vehicles="errorVehicles"
      :title="$t('CRM.Companies.FleetSegments.list.errorTitle')"
      :message="$t('CRM.Companies.FleetSegments.list.errorMessage')"
      :action-text="$t('Common.Actions.ok')"
      data-test-id="error-modal"
      @modal-closed="isDeleteErrorModalOpen = false"
    />
  </div>
</template>
