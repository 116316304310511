const FLEET_SEGMENT = {
  vehiclesCannotBeAdded: 'Vehicles cannot be added to fleet segment',
  vehiclesCannotBeRemoved: 'Vehicles cannot be removed to fleet segment',
  fleetSegmentCannotBeRemoved: 'Fleet segment cannot be removed',
  cancel: 'Fleet segment cancel button click',
  clickOnEdit: 'Fleet segment edit clicked',
  clickViewVehicles: 'Fleet segment view vehicles in creation',
  alwaysClick: 'Fleet segment always click',
  scheduleClick: 'Fleet segment schedule click',
  notDedicatedClick: 'Fleet segment not dedicated click',
};

export const CRM_SEGMENT = {
  FLEET_SEGMENT,
};
