var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      { attrs: { header: { title: _vm.title }, size: _vm.SIZES.medium } },
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _vm._v(" " + _vm._s(_vm.message) + " "),
          _vm._l(_vm.vehicles, function (vehicle) {
            return _c(
              "div",
              { key: vehicle.uuid, staticClass: "py-2" },
              [
                _c(
                  "div",
                  { staticClass: "mb-1" },
                  [
                    _c(
                      "RouterLink",
                      {
                        staticClass: "emobg-link-primary emobg-body-2",
                        attrs: {
                          to: {
                            name: _vm.fleetRouter.vehicles.detail.details,
                            params: {
                              vehicle_uuid: vehicle.uuid,
                            },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(vehicle.license_plate) + ": ")]
                    ),
                  ],
                  1
                ),
                _vm._l(vehicle.bookings, function (booking) {
                  return _c(
                    "div",
                    { key: booking.id, staticClass: "d-flex mb-2" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "emobg-link-primary emobg-body-2 pr-2",
                          attrs: { href: `/carsharing/bookings/${booking.id}` },
                        },
                        [_vm._v(" #" + _vm._s(booking.id) + " ")]
                      ),
                    ]
                  )
                }),
              ],
              2
            )
          }),
        ],
        2
      ),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-end" },
          [
            _c(
              "ui-button",
              {
                on: {
                  clickbutton: function ($event) {
                    return _vm.$emit("modal-closed")
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.actionText) + " ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }