import { RECORD_TYPES } from '@emobg/motion-ui';

export const queryParameters = {
  hitsPerPage: 10,
};

export const contentCells = ({ vehicles }) => [
  {
    title: '',
    type: RECORD_TYPES.checkbox,
    displayPriority: 1,
    model: vehicles,
    columnRatio: 0.01,
    minWidth: 52,
    key: 'uuid',
    value: ({ uuid }) => uuid,
  },
  {
    attributeName: 'license_plate',
    minWidth: 120,
    type: RECORD_TYPES.link,
    title: 'Plate',
    displayPriority: 1,
    columnRatio: 1,
    url: result => `/fleet/vehicles/${result.uuid}/details`,
  },
  {
    title: 'Model',
    minWidth: 120,
    displayPriority: 1,
    columnRatio: 1,
    transformResult: result => `${result.vehicle_brand} ${result.vehicle_model}`,
  },
  {
    title: 'Category',
    attributeName: 'vehicle_category',
    minWidth: 120,
    displayPriority: 1,
    columnRatio: 1,
  },
  {
    title: 'Location',
    attributeName: 'location',
    minWidth: 120,
    displayPriority: 1,
    columnRatio: 1,
  },
  {
    title: 'City',
    attributeName: 'city',
    minWidth: 120,
    displayPriority: 1,
    isCollapseHidden: true,
    columnRatio: 1,
  },
  {
    title: 'Premium Vehicle',
    attributeName: 'is_premium',
    minWidth: 120,
    isCollapseHidden: true,
    columnRatio: 2,
  },
  {
    title: 'Fuel type',
    attributeName: 'vehicle_fuel_type',
    minWidth: 120,
    isCollapseHidden: true,
    columnRatio: 2,
  },
  {
    title: 'Mileage',
    attributeName: 'mileage',
    minWidth: 120,
    isCollapseHidden: true,
    columnRatio: 3,
  },
  {
    title: 'Battery level',
    attributeName: 'battery_level',
    minWidth: 120,
    isCollapseHidden: true,
    columnRatio: 3,
  },
  {
    title: 'Chassis number',
    attributeName: 'chassis_number',
    minWidth: 120,
    isCollapseHidden: true,
    columnRatio: 3,
  },
];

export function vehicleFacets() {
  return [
    {
      type: 'refinementList',
      props: {
        title: this.$t('CRM.Companies.FleetSegments.create.filters.city'),
        attributeName: 'city',
      },
    },
    {
      type: 'refinementList',
      props: {
        title: this.$t('CRM.Companies.FleetSegments.create.filters.location'),
        attributeName: 'location',
        toggled: true,
      },
    },
    {
      type: 'refinementList',
      toggled: true,
      props: {
        title: this.$t('CRM.Companies.FleetSegments.create.filters.brand'),
        attributeName: 'vehicle_brand',
        toggled: true,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: this.$t('CRM.Companies.FleetSegments.create.filters.model'),
        attributeName: 'vehicle_model',
        toggled: true,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: this.$t('CRM.Companies.FleetSegments.create.filters.category'),
        attributeName: 'vehicle_category',
        toggled: true,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: this.$t('CRM.Companies.FleetSegments.create.filters.fuelType'),
        attributeName: 'vehicle_fuel_type',
        toggled: true,
      },
    },
  ];
}
