<script>
import { GenericModalComponent } from '@/components';

export default {
  components: {
    GenericModalComponent,
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ title: $t('CRM.Companies.FleetSegments.create.mappingCategoriesTitle') }"
    :size="SIZES.medium"
  >
    <template slot="body">
      {{ $t('CRM.Companies.FleetSegments.create.mappingCategoriesMessage') }}
    </template>
    <template slot="footer">
      <ui-button @clickbutton="$emit('close-modal')">
        {{ $t('Common.Actions.confirm') }}
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
