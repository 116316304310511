var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiValidationWrapper",
    {
      staticClass: "FleetSegmentBookingRules",
      attrs: {
        "extra-conditions": [!_vm.areCustomBookingRulesSelectedAndEmpty],
      },
      on: { areAllValid: (isValid) => _vm.$emit("isValid", isValid) },
    },
    [
      _c(
        "div",
        { staticClass: "emobg-font-default emobg-font-line-height-large" },
        [
          _c(
            "div",
            { staticClass: "ml-2 mb-3" },
            [
              _c(
                "div",
                [
                  _c("ui-radio", {
                    staticClass: "mb-3",
                    attrs: {
                      value: _vm.isCustomBookingRules,
                      option: false,
                      caption: _vm.$t(
                        "CRM.Companies.FleetSegments.bookingRules.form.options.no.text"
                      ),
                      name: "customBookingRules",
                    },
                    on: {
                      changevalue: () => (_vm.isCustomBookingRules = false),
                    },
                  }),
                  _c(
                    "ui-tooltip",
                    {
                      attrs: {
                        tooltip: _vm.$t(
                          "CRM.Companies.FleetSegments.bookingRules.form.options.no.tooltip"
                        ),
                      },
                    },
                    [
                      _c("ui-icon", {
                        staticClass: "emobg-color-ink-hover",
                        attrs: {
                          icon: _vm.ICONS.infoFull,
                          size: _vm.SIZES.medium,
                          color: _vm.GRAYSCALE.inkLight,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("ui-radio", {
                attrs: {
                  value: _vm.isCustomBookingRules,
                  option: true,
                  caption: _vm.$t(
                    "CRM.Companies.FleetSegments.bookingRules.form.options.yes.text"
                  ),
                  name: "customBookingRules",
                },
                on: { changevalue: () => (_vm.isCustomBookingRules = true) },
              }),
            ],
            1
          ),
          _c("p", {
            staticClass:
              "d-inline-block mb-5 p-2 emobg-background-color-ground-lightest emobg-border-radius-small",
            staticStyle: { "max-width": "623px" },
            domProps: {
              innerHTML: _vm._s(
                _vm.isMixedUse
                  ? _vm.$t(
                      "CRM.Companies.FleetSegments.bookingRules.form.infoMixedUse.withMixedUse"
                    )
                  : _vm.$t(
                      "CRM.Companies.FleetSegments.bookingRules.form.infoMixedUse.withoutMixedUse"
                    )
              ),
            },
          }),
          _vm.showChangedValueAlert
            ? _c(
                "ui-alert",
                {
                  ref: "alert",
                  staticClass: "w-100 mb-3 d-block",
                  attrs: {
                    color: _vm.COLORS.primary,
                    icon: _vm.ICONS.infoFull,
                  },
                },
                [
                  _c("span", { staticClass: "emobg-caption-1" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            `CRM.Companies.FleetSegments.bookingRules.form.changedValuesAlert`
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.isCustomBookingRules
        ? _vm._l(
            _vm.sortedBookingRulesByType,
            function (bookingRulesByType, index) {
              return _c(
                "div",
                {
                  key: index,
                  class: {
                    "mb-2": index + 1 < _vm.sortedBookingRulesByType.length,
                  },
                },
                [
                  _c("h4", { staticClass: "emobg-font-weight-semibold" }, [
                    _vm._v(" " + _vm._s(bookingRulesByType.title) + " "),
                  ]),
                  _c("p", { staticClass: "emobg-body-1" }, [
                    _vm._v(" " + _vm._s(bookingRulesByType.description) + " "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "row mt-2 mb-n3" },
                    _vm._l(bookingRulesByType.rules, function (rule) {
                      return _c(
                        "div",
                        {
                          key: `${rule.type}-${rule.rule}`,
                          staticClass: "col-sm-12 col-md-6 mb-3",
                        },
                        [
                          _c("FleetSegmentBookingRuleInput", {
                            attrs: {
                              description: _vm.$t(
                                `CRM.Companies.FleetSegments.bookingRules.rules.${rule.rule}.description`
                              ),
                              "is-select": rule.isSelect,
                              title: _vm.$t(
                                `CRM.Companies.FleetSegments.bookingRules.rules.${rule.rule}.title`
                              ),
                              "unit-time": rule.unitTime,
                            },
                            model: {
                              value: rule.value,
                              callback: function ($$v) {
                                _vm.$set(rule, "value", $$v)
                              },
                              expression: "rule.value",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]
              )
            }
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }