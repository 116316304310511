var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "FleetSegmentBookingRuleInput d-flex flex-column p-3 emobg-background-color-ground-lightest emobg-border-radius-small",
    },
    [
      _c(
        "label",
        { staticClass: "emobg-font-default emobg-font-weight-semibold mb-1" },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _c("label", { staticClass: "emobg-color-ink-light mb-1" }, [
        _vm._v(_vm._s(_vm.description)),
      ]),
      _c("hr", {
        staticClass:
          "mt-1 mb-3 emobg-border-top-1 emobg-border-color-ink-lighter",
      }),
      _vm.isSelect
        ? _c(
            "MuiSelect",
            _vm._b(
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isPattern: _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                    },
                    expression:
                      "{\n      isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n    }",
                  },
                ],
                model: {
                  value: _vm.inputValue,
                  callback: function ($$v) {
                    _vm.inputValue = $$v
                  },
                  expression: "inputValue",
                },
              },
              "MuiSelect",
              _vm.inputBinds,
              false
            )
          )
        : _c(
            "MuiInputText",
            _vm._b(
              {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isPattern: _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                    },
                    expression:
                      "{\n      isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n    }",
                  },
                ],
                model: {
                  value: _vm.inputValue,
                  callback: function ($$v) {
                    _vm.inputValue = $$v
                  },
                  expression: "inputValue",
                },
              },
              "MuiInputText",
              _vm.inputBinds,
              false
            )
          ),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-end mt-3 emobg-color-ink-light",
        },
        [
          _vm._v(
            " Total duration (minutes): " +
              _vm._s(
                _vm.isNumber(_vm.value)
                  ? _vm.value
                  : _vm.FALLBACK_MESSAGE.noData
              ) +
              " "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }