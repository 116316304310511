<script>
import isNumber from 'lodash/isNumber';
import range from 'lodash/range';
import {
  MuiInputText,
  MuiSelect,
  Validate,
} from '@emobg/motion-ui/v1';
import {
  HOURS_PER_DAY,
  MINUTES_PER_HOUR,
  PRECISION,
} from '@emobg/web-utils';
import { PATTERN_INPUT_VALIDATIONS, toNumberUtil as toNumber } from '@/utils';

export default {
  name: 'FleetSegmentBookingRuleInput',
  components: {
    MuiInputText,
    MuiSelect,
  },
  directives: { Validate },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    description: {
      type: String,
      default: undefined,
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    input: {
      type: Object,
      default: () => ({}),
    },
    unitTime: {
      type: String,
      default: PRECISION.minutes,
    },
    value: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    inputBinds() {
      const binds = {
        label: this.$t(`CRM.Companies.FleetSegments.bookingRules.unitTimes.${this.unitTime}.label`),
        name: `input-${this._uid}`,
        placeholder: this.$t(`CRM.Companies.FleetSegments.bookingRules.unitTimes.${this.unitTime}.placeholder`),
      };
      const gap = 15;
      const RANGE_GAP = [{ label: this.FALLBACK_MESSAGE.noData, value: '' }, ...range(0, (360 + gap), gap)];

      return this.isSelect ? { ...binds, options: RANGE_GAP } : binds;
    },
    inputValue: {
      set(value) {
        const valueToNumber = toNumber(value, false);
        const minutesFromAnyUnitTime = isNumber(valueToNumber)
          ? this.mapTimeConversionToMinutes[this.unitTime](valueToNumber)
          : valueToNumber;
        this.$emit('input', minutesFromAnyUnitTime);
      },
      get() {
        return isNumber(this.value) ? this.mapTimeConversionFromMinutes[this.unitTime](this.value) : this.value;
      },
    },
  },
  created() {
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
    this.mapTimeConversionToMinutes = {
      [PRECISION.days]: value => value * HOURS_PER_DAY * MINUTES_PER_HOUR,
      [PRECISION.hours]: value => value * MINUTES_PER_HOUR,
      [PRECISION.minutes]: value => value,
    };
    this.mapTimeConversionFromMinutes = {
      [PRECISION.days]: value => value / HOURS_PER_DAY / MINUTES_PER_HOUR,
      [PRECISION.hours]: value => value / MINUTES_PER_HOUR,
      [PRECISION.minutes]: value => value,
    };
  },
  methods: {
    isNumber,
  },
};
</script>

<template>
  <div class="FleetSegmentBookingRuleInput d-flex flex-column p-3 emobg-background-color-ground-lightest emobg-border-radius-small">
    <label class="emobg-font-default emobg-font-weight-semibold mb-1">{{ title }}</label>
    <label class="emobg-color-ink-light mb-1">{{ description }}</label>
    <hr class="mt-1 mb-3 emobg-border-top-1 emobg-border-color-ink-lighter">
    <MuiSelect
      v-if="isSelect"
      v-model="inputValue"
      v-validate="{
        isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
      }"
      v-bind="inputBinds"
    />
    <MuiInputText
      v-else
      v-model="inputValue"
      v-validate="{
        isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
      }"
      v-bind="inputBinds"
    />
    <div class="d-flex justify-content-end mt-3 emobg-color-ink-light">
      Total duration (minutes): {{ isNumber(value) ? value : FALLBACK_MESSAGE.noData }}
    </div>
  </div>
</template>
