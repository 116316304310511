<script>
import fleetRouter from '@domains/Carsharing/router/FleetRouterMap';
import { GenericModalComponent } from '@/components';

export default {
  components: {
    GenericModalComponent,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    vehicles: {
      type: Array,
      default: () => ([]),
    },
    title: {
      type: String,
      default: '',
    },
    actionText: {
      type: String,
      default: '',
    },
  },
  created() {
    this.fleetRouter = fleetRouter;
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ title }"
    :size="SIZES.medium"
    v-on="$listeners"
  >
    <div slot="body">
      {{ message }}
      <div
        v-for="vehicle in vehicles"
        :key="vehicle.uuid"
        class="py-2"
      >
        <div class="mb-1">
          <RouterLink
            :to="{
              name: fleetRouter.vehicles.detail.details,
              params: {
                vehicle_uuid: vehicle.uuid,
              }
            }"
            class="emobg-link-primary emobg-body-2"
          >
            {{ vehicle.license_plate }}:
          </RouterLink>
        </div>
        <div
          v-for="booking in vehicle.bookings"
          :key="booking.id"
          class="d-flex mb-2"
        >
          <a
            :href="`/carsharing/bookings/${booking.id}`"
            class="emobg-link-primary emobg-body-2 pr-2"
          >
            #{{ booking.id }}
          </a>
        </div>
      </div>
    </div>
    <div slot="footer">
      <div class="d-flex justify-content-end">
        <ui-button @clickbutton="$emit('modal-closed')">
          {{ actionText }}
        </ui-button>
      </div>
    </div>
  </GenericModalComponent>
</template>
